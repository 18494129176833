import React, { useState, useEffect } from "react";
import eyeIcon from "../assets/eye.png"; 
import eyeSlashIcon from "../assets/eye-2.png";
import "../styles/SignUpComponent.css";
import { Link, useNavigate } from "react-router-dom";
import { userPhoneNoValidation , validatePassword} from "./valdations";
import axios from "axios";

function SignUpComponent() {
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [isTypingPhone, setIsTypingPhone] = useState(false);
  const [isTypingPassword, setIsTypingPassword] = useState(false); 
  const navigate = useNavigate(); // Hook to programmatically navigate

  // vaidate the phone number
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    // Clear the phone error immediately if the user starts typing
    if (value === "") {
      setPhoneError(""); // Clear error message when the input is cleared
    } else {
      // Validate the phone number on change
      if (!userPhoneNoValidation(value)) {
        setPhoneError("Phone number is invalid"); // Set error if invalid
      } else {
        setPhoneError(""); // Clear error if valid
      }
    }
  };
  useEffect(() => {
    if (userPhoneNoValidation(phoneNumber)) {
      setPhoneError(""); // Clear the error if the phone number becomes valid
    } else if (phoneNumber) {
      setPhoneError("Phone number is invalid");
    }
  }, [phoneNumber]);
  
  // Validate password
  useEffect(() => {
    const passwordValidation = validatePassword(password);
    if (password) { // Only check if there is a password entered
      if (passwordValidation.valid) {
        setPasswordError(""); // Clear error if password is valid
      } else {
        setPasswordError(passwordValidation.message); // Set specific error message
      }
    } else {
      setPasswordError(""); // Clear error if password is empty
    }
  }, [password]);

  const handleSubmit = async (e) => {
    
    e.preventDefault();

    setPhoneError("");
    setPasswordError("");

    // Validation (basic)
    if (!name || !email || !phoneNumber || !password) {
      alert("All fields are required");
      return;
    }

      // Validate phone number (again for double-checking)
      if (!userPhoneNoValidation(phoneNumber)) {
        setPhoneError("Phone number is invalid");
        return;
      }
  
      // Validate password
      const passwordValidation = validatePassword(password);
      if (!passwordValidation.valid) {
        setPasswordError(passwordValidation.message);
        return;
      }

      if (phoneError || passwordError) {
        return;
      }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/register`,
        {
          name,
          email,
          phoneNumber,
          password,
        }
      );
      // Check for successful response status code (optional)
      if (response.status === 200 || response.status === 201) {
        // Access data from response (e.g., success message)
        // const responseData = response.message;
        // console.log(response);
        alert("User Created successfully"); // Assuming a message property in response data
      } else {
        // Handle non-success response codes
        alert("Registration failed. Please try again.");
      }

      navigate("/login");
    } catch (error) {
      // Handle error (e.g., show an error message)
      alert("Registration failed. Please try again.");
      console.error("There was an error registering the user:", error);
    }
  };
    // Toggle password visibility
    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev); // Toggle the password visibility
    };

  return (
    
    <div className="loginComponentContainer">
      <div className="insideContainer card1">
        <div className="logoDivBox">
          <div>
            <img
              className="imgPngLogo"
              src="https://res.cloudinary.com/dfagcn631/image/upload/v1721987516/logoimg_epbsrt.png"
              alt="NutriiNuts Logo"
            />
          </div>
          <div className="titleBoxLogo">
            <span>NutriiNuts</span>
          </div>
        </div>
        <hr className="borderComponentDivider" />
        <div className="loginTextBox">
          <span>SignUp</span>
        </div>
        <form className="loginInputField" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="example@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="tel"
            placeholder="Enter Your Phone Number"
            value={phoneNumber}
            // onChange={(e) => setPhoneNumber(e.target.value)}
            onChange={handlePhoneNumberChange}
          />
          {phoneError && (
            <span className="errorMessage">{phoneError}</span>
          )}
           <div className="passwordContainer">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setIsTypingPassword(true);
                if (e.target.value === "") {
                  setPasswordError("");
                  setIsTypingPassword(false);
                }
              }}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="togglePasswordButton"
            >
              <img
                src={showPassword ? eyeSlashIcon : eyeIcon}
                alt={showPassword ? "Hide password" : "Show password"}
                width="24"
                height="24"
              />
            </button>
          </div>
          {!isTypingPassword && (
            <span className="passwordHelp">
              at least 8 characters including one special character and some digits.
            </span>
          )}
          {passwordError && <span className="errorMessage">{passwordError}</span>}

          <div className="rememberAndNotAUserMainBox">
            <div className="notAUserBox">
              <h6>
                Already a User?{" "}
                <Link to="/login">
                  <span>Login</span>
                </Link>
              </h6>
            </div>
          </div>
          <div className="loginBtnContainer">
            <button type="submit">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpComponent;
