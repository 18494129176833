// import React from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "../styles/ImageSlider.css";
// import { Carousel } from "react-responsive-carousel";
// function ImageSlider() {
//   return (
//     <div className="imageSliderContainer">
//       <Carousel autoPlay infiniteLoop>
//         <div>
//           <img
//             src="https://www.farmley.com/cdn/shop/files/May2023_Website-Home-Banner02_2000x.jpg?v=1687326806"
//             alt=""
//           />
//         </div>
//         <div>
//           <img
//             src="https://www.farmley.com/cdn/shop/files/Website_Panchmewa_2000x.jpg?v=1674645463"
//             alt=""
//           />
//         </div>
//         <div>
//           <img
//             src="https://www.farmley.com/cdn/shop/files/May2023_Website-Home-Banner_2000x.jpg?v=1687326758"
//             alt=""
//           />
//         </div>
//         <div>
//           <img
//             src="https://www.farmley.com/cdn/shop/files/Nuts_Web_2000x.jpg?v=1674644890"
//             alt=""
//           />
//         </div>
//       </Carousel>
//     </div>
//   );
// }

// export default ImageSlider;
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/ImageSlider.css";
import { Carousel } from "react-responsive-carousel";

function ImageSlider() {
  return (
    <div className="imageSliderContainer">
      <Carousel
        autoPlay              // Enables automatic sliding
        infiniteLoop          // Keeps the carousel looping
        interval={2000}       // 3000 ms = 3 seconds between slides (adjustable)
        showArrows={false}    // Hides the arrows
        showThumbs={false}    // Hides the thumbnail indicators
        showStatus={false}    // Hides the slide status (e.g., "1 of 5")
        stopOnHover={false}   // Keeps the autoplay even when hovered
        transitionTime={500}  // Sets the transition speed (optional)
      >
        <div className = "sliderImage">
          <img
            src="https://www.farmley.com/cdn/shop/files/May2023_Website-Home-Banner02_2000x.jpg?v=1687326806"
            alt=""
          />
        </div>
        <div className = "sliderImage">
          <img
            src="https://www.farmley.com/cdn/shop/files/Website_Panchmewa_2000x.jpg?v=1674645463"
            alt=""
          />
        </div>
        <div className = "sliderImage">
          <img
            src="https://www.farmley.com/cdn/shop/files/May2023_Website-Home-Banner_2000x.jpg?v=1687326758"
            alt=""
          />
        </div>
        <div className = "sliderImage">
          <img
            src="https://www.farmley.com/cdn/shop/files/Nuts_Web_2000x.jpg?v=1674644890"
            alt=""
          />
        </div>
      </Carousel>
    </div>
  );
}

export default ImageSlider;