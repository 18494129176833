import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StarRate from "./StarRate";

const ProductDetails = () => {
    const navigate =useNavigate();
  const { productId } = useParams(); // Extract productId from the URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState({});
  const handleClick = (product) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Login/Signup first"); // Show the alert
      navigate("/login"); // Redirect to the login page
      return;
    }
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [product.products_id]: true,
    }));

    setTimeout(() => {
      handleAddToCart(product._id);
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [product.products_id]: false,
      }));
    }, 1000);
  };
  const handleAddToCart = async (products_id) => {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/cart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ products_id, quantity: 1 }),
    }); // Refresh cart after adding
  };

  useEffect(() => {
    // Function to fetch product details from multiple endpoints
    const fetchProductDetails = async () => {
      const endpoints = [
        `${process.env.REACT_APP_BACKEND_URL}/api/allproducts/${productId}`,
        `${process.env.REACT_APP_BACKEND_URL}/api/gifting/${productId}`,
        `${process.env.REACT_APP_BACKEND_URL}/api/newlaunches/${productId}`
      ];

      for (const endpoint of endpoints) {
        try {
          const response = await fetch(endpoint);
          if (response.ok) {
            const data = await response.json();
            setProduct(data); // Set product if found
            setLoading(false);
            return;
          }
        } catch (error) {
          console.error(`Error fetching from ${endpoint}:`, error);
        }
      }

      // If no product was found in any of the endpoints
      setLoading(false);
    };

    fetchProductDetails();
  }, [productId]);

  if (loading) {
    return <div>Loading product details...</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  return (
    <div className="product-details">
      <h2>{product.productName}</h2>
      <img src={product.productImageUrl} alt={product.productName} />
      <p>Price: Rs. {product.productPrice}</p>
      <p>Description: {product.productDescription}</p>
      <div className="productPriceContainer">
                  <StarRate userId={localStorage.getItem("id")} productId={product._id} productModel="allProducts"/>
                </div>
      {/* Add other product details as needed */}
      <div className="productAddToCartButton">
                <button
                  className={
                    loadingStates[product.products_id]
                      ? "button loading"
                      : "button"
                  }
                  onClick={() => handleClick(product)}
                  disabled={loadingStates[product.products_id]}
                >
                  <span>Add to cart</span>
                  <div className="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
    </div>
  );
};

export default ProductDetails;
