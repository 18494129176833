import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/Payment.css";

function Payment() {
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send payment details to the backend or handle payment processing
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/confirm`,
        {
          paymentMethod,
          cardNumber,
          expiryDate,
          cvv,
          cartItems: JSON.parse(localStorage.getItem("cartItems")),
          deliveryAddress: JSON.parse(localStorage.getItem("deliveryAddress")),
        }
      );
      console.log(response);

      // Clear cart and address after successful payment
      localStorage.removeItem("cartItems");
      localStorage.removeItem("deliveryAddress");
      alert("Payment successful!");
      navigate("/");
    } catch (error) {
      alert("Payment failed. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="paymentContainer">
      <h2>Payment</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            <input
              type="radio"
              value="creditCard"
              checked={paymentMethod === "creditCard"}
              onChange={() => setPaymentMethod("creditCard")}
            />
            Credit Card
          </label>
          {paymentMethod === "creditCard" && (
            <>
              <input
                type="text"
                placeholder="Card Number"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
              <input
                type="text"
                placeholder="Expiry Date (MM/YY)"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
              <input
                type="text"
                placeholder="CVV"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </>
          )}
        </div>
        <button type="submit">Pay Now</button>
      </form>
    </div>
  );
}

export default Payment;
